  .piano-container {
      background: rgba(0, 0, 0, 0.5);
      bottom: 100px;
      left: 0px;
      top: 20px;
      height: 220px;
      position: absolute;
      width: 100%;
      z-index: 99;
      color: white;
      padding: 20px;
      display: flex;
      justify-content: center;
      box-sizing: border-box;
  }
  
  .ReactPiano__Keyboard {
      max-width: 100%;
  }

  
.piano-container .piano-instrument-config {
    display: none;
  }
.close_tool {
  position: absolute;
  left: 5px;
  top:5px;
  cursor: pointer;
}

.ReactPiano__Key--active {
  background-color: #CF2435 !important;
  color: white;
}

.ReactPiano__Key--active.ReactPiano__Key--natural {
  border:none !important;
}
.piano-container {
  background: rgba(0, 0, 0, 0.7);
}

.ReactPiano__Keyboard {
  height: 180px !important;
}

.main-container-piano {
  height: 100%;
  width: 100%;
}


.piano-over-lay {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 999999;
}


.web-piano-container.piano-container {
  background: transparent !important;
  padding: 0 !important;
}

.web-piano-container .ReactPiano__Keyboard {
  width: 100% !important;
  height: 200px !important;
}